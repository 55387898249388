exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-competition-tsx": () => import("./../../../src/pages/competition.tsx" /* webpackChunkName: "component---src-pages-competition-tsx" */),
  "component---src-pages-event-photos-tsx": () => import("./../../../src/pages/event-photos.tsx" /* webpackChunkName: "component---src-pages-event-photos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-location-tsx": () => import("./../../../src/pages/location.tsx" /* webpackChunkName: "component---src-pages-location-tsx" */),
  "component---src-pages-presenters-index-tsx": () => import("./../../../src/pages/presenters/index.tsx" /* webpackChunkName: "component---src-pages-presenters-index-tsx" */),
  "component---src-pages-press-index-tsx": () => import("./../../../src/pages/press/index.tsx" /* webpackChunkName: "component---src-pages-press-index-tsx" */),
  "component---src-pages-press-photos-tsx": () => import("./../../../src/pages/press/photos.tsx" /* webpackChunkName: "component---src-pages-press-photos-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-safety-tsx": () => import("./../../../src/pages/safety.tsx" /* webpackChunkName: "component---src-pages-safety-tsx" */),
  "component---src-pages-schedule-index-tsx": () => import("./../../../src/pages/schedule/index.tsx" /* webpackChunkName: "component---src-pages-schedule-index-tsx" */),
  "component---src-pages-sponsor-index-tsx": () => import("./../../../src/pages/sponsor/index.tsx" /* webpackChunkName: "component---src-pages-sponsor-index-tsx" */),
  "component---src-pages-volunteer-index-tsx": () => import("./../../../src/pages/volunteer/index.tsx" /* webpackChunkName: "component---src-pages-volunteer-index-tsx" */)
}

